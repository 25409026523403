import { lightFormat, parse } from 'date-fns';

export const action_maker = (action) => {
	// action.ack = timestamp();
	action.data = action.data || []; // initize the data first, for get more flow
	return { ...action, type: action.cons.FETCHING };
};

export const action_ui_maker = action => ({ ...action, type: action.cons.REQUEST_UPDATE });

export const constants_maker = str => ({
	PAGE: `${str}_PAGE`,
	FETCHING: `${str}_FETCHING`,
	SUCCESS: `${str}_SUCCESS`,
	FAILURE: `${str}_FAILURE`,
});

export const constants_ui_maker = str => ({
	REQUEST_UPDATE: `${str}_REQUEST_UPDATE`,
	SUCCESS: `${str}_SUCCESS`,
	FAILURE: `${str}_FAILURE`,
});

export const simpleInitialState = {
	data: [],
	dataFetched: false,
	isFetching: false,
	error: false
};

export const fetchingState = (state, action) => ({
	...state,
	isFetching: true,
	type: action.type
});

export const successState = (state, action) => ({
	...state,
	dataFetched: true,
	isFetching: false,
	error: false,
	timestamp: Date.now(),
	type: action.type,
	...action
});

export const failureState = (state, action) => ({
	...state,
	isFetching: false,
	error: true,
	timestamp: Date.now(),
	api: action.cons,
	type: action.type,
	...action
});

export const simpleReducer = (cons, state, action) => {
	switch (action.type) {
	case cons.FETCHING: return fetchingState(state, action);
	case cons.SUCCESS: return successState(state, action);
	case cons.FAILURE: return failureState(state, action);
	default: return state;
	}
};

export const multiReducer = (cons_arr, state, action) => {
	for (const i in cons_arr) {
		const cons = cons_arr[i];
		switch (action.type) {
		case cons.FETCHING: return fetchingState(state, action);
		case cons.SUCCESS: return successState(state, action);
		case cons.FAILURE: return failureState(state, action);
		// no default
		}
	}
	return state;
};

export const simpleReducerUi = (cons, state, action) => {
	if (action.type === cons.SUCCESS) {
		return {
			...state,
			...action
		};
	}
	return state;
};

export const compareJson = (json1, json2) => JSON.stringify(json1) === JSON.stringify(json2);
export const compare = (object1, object2) => object1 === object2;

// export const receiveApi = (nextProps, oriProps, cons) => (nextProps.type === cons.SUCCESS && !compareJson(nextProps.data, oriProps.data));
export const receiveApi = (nextProps, oriProps, cons) => (nextProps.type === cons.SUCCESS && !compare(nextProps.timestamp, oriProps.timestamp));

export const jsonToQueryStr = (params) => {
	if (!params) {
		return '';
	}
	return Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
};

export const convertFloatToPrice = (float, minimumFractionDigits = 2) => {
	if (!float) {
		const price = `$ ${Intl.NumberFormat('zh-Hant', {
			// style: 'currency',
			// currency: 'HKD',
			minimumFractionDigits,
		}).format(0)}`;
		return (price);
	}

	const price = `${float < 0 ? '- ' : ''}$ ${Intl.NumberFormat('zh-Hant', {
		// style: 'currency',
		// currency: 'HKD',
		minimumFractionDigits,
	}).format(Math.abs(float))}`;
	return (price);
};

// const formatter = new Intl.NumberFormat('zh-HK', {
//   style: 'currency',
//   currency: 'HKD',
//   minimumFractionDigits: 0
// });

export function convertPaddingStyle(a, b, c, d) {
	return {
		paddingTop: a,
		paddingRight: b || a,
		paddingBottom: c || a,
		paddingLeft: d || (b || a)
	};
}

export function validateNumericInput(text) {
	if (/^\d+$/.test(text) || text === '') {
		return Number(text);
	}
	return 0;
}

export function convertDateForApi(date, time = '00:00:00') {
	const year = date.getFullYear();
	const month = (date.getMonth() + 1 < 10 ? '0' : '') + (date.getMonth() + 1);
	const day = (date.getDate() < 10 ? '0' : '') + date.getDate();
	const result = `${year}-${month}-${day}${time ? ` ${time}` : ''}`;
	return result;
}

export function extractDateFromDate(input, format = null) {
	if (!format) {
		if (input.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
			format = 'yyyy-MM-dd HH:mm:ss';
		} else {
			format = 'yyyy-MM-dd HH:mm:ss.SSS';
		}
	}
	const date = parse(input, format, new Date());
	return lightFormat(date, 'yyyy-MM-dd');
}

export function extractDateTimeFromDate(input, format = null) {
	if (!format) {
		if (input.match(/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/)) {
			format = 'yyyy-MM-dd HH:mm:ss';
		} else {
			format = 'yyyy-MM-dd HH:mm:ss.SSS';
		}
	}
	const date = parse(input, format, new Date());
	return lightFormat(date, 'yyyy-MM-dd HH:mm:ss');
}

export const setDefaultDateFrom = () => {
	// default 1 year ago
	let defaultDateFrom = new Date(
		new Date().getFullYear() - 2,
		new Date().getMonth(),
		new Date().getDate()
	);
	// console.log(defaultDateFrom);
	return defaultDateFrom;
};